import { reactive, ref, computed, onMounted, onUnmounted } from 'vue';
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import { required, minLength, maxLength } from '@/helpers/i18n/validators';

export default function() {
	const form = reactive({
		code: ''
	});

	const timer = reactive({
		id: null,
		count: 0,
		expires_in: null
	});

	const codeLength = 4;
	const interval = ref(null);
	const sendingCode = ref(false);
	const inputsComponent = ref(null);
	const canRequestCode = computed(() => (timer.count == 0));
	const validation = useVuelidate({
		code: {
			required,
			minLength: minLength(codeLength),
			maxLength: maxLength(codeLength)
		}
	}, form);

	const resendDescription = computed(() => {
		if(canRequestCode.value) return 'Reenviar código';
		return `Reenviar código en ${timer.count} segundos`;
	});

	const updateTimer = () => {
		if(timer.count <= 0) return;
		timer.expires_in = moment(timer.expires_in.subtract(1, 'seconds'));
		timer.count = timer.count - 1;
	}

	onMounted(() => interval.value = setInterval(() => { updateTimer() }, 1000));

	onUnmounted(() => {
		if(interval.value) clearInterval(interval.value);
	});

	return { form, timer, codeLength, sendingCode, inputsComponent, canRequestCode, resendDescription, validation };
}