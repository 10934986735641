<template>
	<layouts-basic>
		<form @submit.prevent="submit">
			<div class="mb-7 text-center">
				<div class="d-block mb-2"><i class="bi bi-phone display-1 text-dark"></i></div>
				<div class="d-block h3">Validá tu teléfono</div>
				<div class="d-block mb-2">Ingresa el código de verificación que enviamos a</div>
				<div class="d-block h4"><strong>{{ phoneFormatted }}</strong></div>
			</div>
			<div class="mb-3 text-center">
				<user-phone-input :length="codeLength" @updated="updated" ref="inputsComponent" />
			</div>
			<div class="mb-3 text-center" v-if="validationExternal.length > 0 || validation.code.$errors.length > 0">
				<div class="invalid-feedback d-block" v-for="error of validation.code.$errors" v-bind:key="error.$uid">{{ error.$message }}</div>
				<div class="invalid-feedback d-block" v-for="(error, index) in validationExternal" v-bind:key="index">{{ error }}</div>
			</div>
			<div class="mt-5">
				<button type="submit" class="btn btn-primary w-100" :disabled="sendingCode || submitting">Verificar</button>
			</div>
			<div class="mt-5">
				<button type="submit" class="btn btn-outline-warning w-100" :disabled="sendingCode || !canRequestCode" @click="requestCode">{{ resendDescription }}</button>
			</div>
			<div class="mt-5">
				<router-link :to="{name: 'user.phone.update'}" class="btn btn-neutral w-100">Cancelar</router-link>
			</div>
		</form>
	</layouts-basic>
</template>

<script>
	import { toRefs, computed, onMounted } from 'vue';
	import store from '@/store';
	import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
	import MeRepository from '@/repositories/MeRepository';
	import composableOtp from '@/composables/otp';
	import composableRedirect from '@/composables/redirect';
	import composableForm from '@/composables/form';
	import moment from 'moment';

	export default {
		setup() {
			const { form, timer, codeLength, sendingCode, inputsComponent, canRequestCode, resendDescription, validation } = composableOtp();
			const { redirectNext } = composableRedirect();
			const { submitting, validationExternal, catchError } = composableForm();

			async function submit() {
				if(!await validation.value.$validate()) return;

				submitting.value = true;
				MeRepository.phoneVerificationCheck(form.code).then(onSuccess).catch(catchError);
			}

			const onSuccess = () => {
				store.dispatch('auth/phoneVerified');
				redirectNext();
			}

			const hasUser = computed(() => store.getters['auth/hasUser']);
			const user = computed(() => store.getters['auth/user']);
			const phoneFormatted = computed(() => {
				if(!hasUser.value || !user.value.phone || !isValidPhoneNumber(user.value.phone)) return null;
				return parsePhoneNumber(user.value.phone).formatNational();
			});

			const updated = (data) => {
				form.code = data;
				validationExternal.value = [];
			}

			const requestCode = () => {
				if(!canRequestCode.value) return;

				inputsComponent.value.setCode(null);
				sendingCode.value = true;

				MeRepository.phoneVerificationRequest()
					.then((response) => {
						timer.count = response.data.expires_in;
						timer.expires_in = moment(response.data.expires_in * 1000);
						validationExternal.value = [];
						sendingCode.value = false;
					})
					.catch((e) => {
						catchError(e);
						sendingCode.value = false;
					});
			}

			onMounted(() => requestCode());

			if(store.getters['auth/userHasVerifiedPhone']) return redirectNext();

			return { ...toRefs(form), inputsComponent, codeLength, validation, validationExternal, phoneFormatted, updated, submitting, submit, sendingCode, canRequestCode, requestCode, resendDescription };
		}
	}
</script>

<style scoped>
	.bi-phone {
		font-size: 4rem;
	}
</style>